import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import {Checkbox, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress} from '@mui/material';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom';
import axios from "../../utils/axios";
import { InputRow, MuiText } from 'components/RenewalStyledFields';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';
import {Info} from "@material-ui/icons";

const StyledSelect = styled(Select)`
    width: 505px;
    top: -5px;
    margin-bottom: 12px;
    padding: 5px 15px;
    font-size: 1rem;
    text-align: left;
    height: 40px;
    `;
const StyledSelectShort = styled(Select)`
    width: 250px;
    top: -6px;
    margin-bottom: 12px;
    padding: 5px 15px;
    font-size: 1rem;
    text-align: left;
    height: 40px;
    `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const ReferralModal = ({ open, onClose, tableData, isOpen }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = React.useState({
        a_id:tableData.a_id,
        a_type:tableData.a_type,
        account_id : tableData.raf_account_id,
        account_number : tableData.raf_account_number,
        account_start : tableData.raf_account_start,
        account_status : tableData.raf_account_status,
        account_transaction_id : tableData.raf_account_transaction_id,
        amount_paid : tableData.raf_amount_paid ? tableData.raf_amount_paid : "50.00",
        bill_id : tableData.raf_bill_id,
        created_by_username : tableData.raf_created_by_username,
        needs_create: tableData.needs_create,
        needs_update:tableData.needs_update,
        needs_status:tableData.needs_status,
        raf_account_id: tableData.raf_raf_account_id,
        raf_account_number:tableData.raf_raf_account_number,
        raf_account_start: tableData.raf_raf_account_start,
        raf_account_status: tableData.raf_raf_account_status,
        raf_created_by_username: tableData.raf_raf_created_by_username,
        raf_id:tableData.raf_id,
        raf_record_id: tableData.raf_record_id,
        raf_status: tableData.raf_status,
        //raf_type: tableData.raf_type,
        rc_amount_paid: tableData.rc_amount_paid ? tableData.rc_amount_paid : "50.00",
        rc_created_by_username: tableData.rc_created_by_username,
        rc_notes: tableData.rc_notes,
        rc_status: tableData.rc_status,
        ref_account_id: tableData.raf_ref_account_id,
        ref_account_number:tableData.raf_ref_account_number,
        ref_account_start: tableData.raf_ref_account_start,
        ref_account_status: tableData.raf_ref_account_status,
        referral_code:  (tableData.raf_referral_code ? tableData.raf_referral_code : null),
        transaction_date:tableData.raf_transaction_date,

    });

    useEffect(() => {
        console.log("Account Status:", formValue.account_status); // This logs the account status whenever it changes.
    }, [formValue]);

    const handleCancel = (e) => {
        onClose();
    }

    const handleSubmit = async (e) => {
        console.log('handleSubmit --- Start')
        e.preventDefault();

        if (isOpen.editType === 'create') {
            setLoading(true);
            try {
                const {
                    account_id,
                    ref_account_id,
                    amount_paid,
                    rc_amount_paid,
                    raf_status,
                    rc_status,
                    raf_notes,
                    rc_notes
                } = formValue;


                const createReferred = formValue.amount_paid > "0";
                const createReferrer = formValue.rc_amount_paid > "0";
                console.log(isOpen.editType);
                console.log({
                    url: "/internal/raf/create_raf_record",
                    create_referred: createReferred,
                    create_referrer: createReferrer,
                    amount: amount_paid,
                    rc_amount: rc_amount_paid,
                    account_id: account_id,
                    ref_account_id: ref_account_id,
                    raf_status: raf_status,
                    rc_status: rc_status,
                    raf_notes : raf_notes,
                    rc_notes : rc_notes
                });

                const response = await axios.post('/internal/raf/create_raf_record', {
                    create_referred: createReferred,
                    create_referrer: createReferrer,
                    amount: amount_paid,
                    rc_amount: rc_amount_paid,
                    account_id: account_id,
                    ref_account_id: ref_account_id,
                    raf_status: raf_status,
                    rc_status: rc_status,
                    raf_notes : raf_notes,
                    rc_notes : rc_notes
                }, {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
                });

                if (response.status === 200) {
                    console.log('--------------------------------------------------------------------')
                    console.log('RAF record created successfully');
                    console.log(response.data.records.raf_account.id);
                    const response2 = await axios.get(`/internal/referral/${response.data.records.raf_account.id}`, {
                        headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
                    });
                    if (response2.status === 200 ) {
                        console.log(response2.data);
                    }
                    onClose(response2.data.data, true);
                    console.log('--------------------------------------------------------------------')
                    console.log('RAF record created successfully');
                }

            } catch (error) {
                console.error('Failed to create RAF record', error);
            } finally {
                setLoading(false);
            }
        } else if (isOpen.editType === 'update') {
            setLoading(true);
            try {
                const { account_number, raf_account_number, account_id, raf_account_id, raf_id } = formValue;

                if (formValue.a_type === 'referrer') {
                    console.log(isOpen.editType);
                    console.log({
                        url: "/internal/raf/update_referred",
                        account_number: account_number,
                        account_id: account_id,
                        raf_id: raf_id,
                        ref_account_number: raf_account_number,
                        ref_account_id: raf_account_id,
                    });
                    //Update Referrer Record
                    const response = await axios.put('/internal/raf/update_referrer', {
                        account_number: account_number,
                        account_id: account_id,
                        raf_id: raf_id,
                        ref_account_number: raf_account_number,
                        ref_account_id: raf_account_id,
                    }, {
                        headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
                    });

                    if (response.status === 200) {
                        console.log('Referrer record updated successfully');
                        await setFormValue(prev => ({
                            ...prev,
                            needs_update: false
                        }));
                        tableData.account_id = formValue.account_id;
                        tableData.account_number = formValue.account_number;
                        tableData.account_status = formValue.account_status;
                        tableData.account_start = formValue.account_start;
                        tableData.raf_account_id = formValue.raf_account_id;
                        tableData.raf_account_number = formValue.raf_account_number;
                        tableData.raf_account_status = formValue.raf_account_status;
                        tableData.raf_account_start = formValue.raf_account_start;
                        onClose(true);
                    }
                } else if (formValue.a_type === 'referred') {
                    console.log(isOpen.editType);
                    console.log({
                        url: "/internal/raf/update_referred",
                        account_number: account_number,
                        account_id: account_id,
                        raf_id: raf_id,
                        ref_account_number: raf_account_number,
                        ref_account_id: raf_account_id,
                    });
                    return;
                    // Update Referred Record
                    const response = await axios.put('/internal/raf/update_referred', {
                        account_number: account_number,
                        ref_account_number: raf_account_number,
                        account_id: account_id,
                        ref_account_id: raf_account_id,
                        raf_id: raf_id,
                    }, {
                        headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
                    });

                    if (response.status === 200) {
                        console.log('Referred record updated successfully');
                        onClose();
                    }
                }
            } catch (error) {
                console.error('Failed to update RAF record', error);
            } finally {
                setLoading(false);
            }
        }
    };


    const handleChange = async (e) => {
        setLoading(true);
        const { name, value, type, checked } = e.target;
        // setFormValue({
        //     ...formValue,
        //     [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 : e.target.value
        // });
       /*setFormValue(prev => ({
            ...prev,
        }));*/
        if (isOpen.editType === 'update') {
            if (formValue.a_type === 'referrer' && name === 'a_type' && value === 'referred') {
                // Copy Ref Account Number to Account Number when type is 'Referred'
                const tmp_account_id = formValue.account_id;
                const tmp_account_number = formValue.account_number;
                const tmp_account_status = formValue.account_status;
                const tmp_account_start = formValue.account_start;
                const tmp_raf_account_id = formValue.raf_account_id;
                const tmp_raf_account_number = formValue.raf_account_number;
                const tmp_raf_account_status = formValue.raf_account_status;
                const tmp_raf_account_start = formValue.raf_account_start;
                await setFormValue(prev => ({
                    ...prev,
                    account_id : tmp_raf_account_id,
                    account_number: tmp_raf_account_number,
                    account_status: tmp_raf_account_status,
                    account_start: tmp_raf_account_start,
                    raf_account_id : tmp_account_id,
                    raf_account_number: tmp_account_number,
                    raf_account_status: tmp_account_status,
                    raf_account_start: tmp_account_start,
                    [name]: type === 'checkbox' ? checked ? 1 : 0 : value
                }));

                //handleAccountLookup(formValue.ref_account_number, true);
            } else if (formValue.a_type === 'referred' && name === 'a_type' && value === 'referrer') {
                const tmp_account_id = formValue.account_id;
                const tmp_account_number = formValue.account_number;
                const tmp_account_status = formValue.account_status;
                const tmp_account_start = formValue.account_start;
                const tmp_raf_account_id = formValue.raf_account_id;
                const tmp_raf_account_number = formValue.raf_account_number;
                const tmp_raf_account_status = formValue.raf_account_status;
                const tmp_raf_account_start = formValue.raf_account_start;
                await setFormValue(prev => ({
                    ...prev,
                    account_id : tmp_raf_account_id,
                    account_number: tmp_raf_account_number,
                    account_status: tmp_raf_account_status,
                    account_start: tmp_raf_account_start,
                    raf_account_id : tmp_account_id,
                    raf_account_number: tmp_account_number,
                    raf_account_status: tmp_account_status,
                    raf_account_start: tmp_account_start,
                    [name]: type === 'checkbox' ? checked ? 1 : 0 : value
                }));
            }
        } else if (isOpen.editType === 'create') {
            let tmp_rc_amount_paid =formValue.rc_amount_paid;
            if (name === 'rc_status' && value === "7") {
                if (formValue.rc_amount_paid > "0") {
                    tmp_rc_amount_paid = "0.00"
                }
            }
            let tmp_amount_paid =formValue.amount_paid;
            if (name === 'raf_status') {
                if (value === "7") {
                    if (formValue.amount_paid > "0") {
                        tmp_amount_paid = "0.00"
                    }
                } else if(formValue.raf_status === "7") {
                    if (formValue.amount_paid === "0.00") {
                        tmp_amount_paid = tableData.amount_paid ? tableData.amount_paid : "50.00"
                    }
                }
            }
            console.log('-------------------create----------------------------')
            const tmp_raf_status = formValue.raf_status;
            const tmp_rc_status = formValue.rc_status;
            await setFormValue(prev => ({
                ...prev,
                rc_amount_paid: tmp_rc_amount_paid,
                amount_paid: tmp_amount_paid,
                [name]: type === 'checkbox' ? checked ? 1 : 0 : value
            }));
        }
        setLoading(false);
    }

    const handleAccountLookup = async (accountNumber, isRefAccount = false) => {
        setLoading(true);
        try {
            const response = await axios.get(`/internal/raf/account_lookup/${accountNumber}`, {
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
            });
            if (response.data) {
                setFormValue(prev => ({
                    ...prev,
                    ...(isRefAccount ? {
                        ref_account_id: response.data.account_id,
                        ref_account_status: response.data.status,
                        ref_account_start: response.data.account_start_date,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        ref_account_number: accountNumber,
                        referral_code: response.data.referral_code
                    } : {
                        account_id: response.data.account_id,
                        account_status: response.data.status,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        account_number: accountNumber,
                        account_start: response.data.account_start_date,
                    })
                }));
            }
        } catch (error) {
            console.error('Failed to fetch account data', error);
        }
        setLoading(false);
    }
    const createDisabled = () => {
        let isDisabled = false;
        if (formValue.account_id === null) {
            isDisabled = true;
        }
        if (formValue.ref_account_id === null) {
            isDisabled = true;
        }
        if (formValue.raf_status === null) {
            isDisabled = true;
        }
        if (formValue.rc_status === null) {
            isDisabled = true;
        }
        return isDisabled;
    }

    if (!open) {
        // console.log('not opened...');
        return null
    } else {
        console.log('isOpen', isOpen);
        console.log('tableData', tableData);
        console.log('formValue', formValue);
    }
    return ReactDom.createPortal(
        <>
            <div key="OS1" style={OVERLAY_STYLES} />
            <div key="FM1" className={classes.modal}>
                <form>
                    {loading ? <CircularProgress /> : ( // Show spinner when loading
                        <div className={commonClasses.flexColumn} style={{alignItems: "start"}}>
                                <Typography variant="h5" sx={{mb: 3, alignSelf: "center"}}>
                                    {tableData.needs_status  ? "Update Status" : (tableData.needs_create? "Create new Record" :
                                        tableData.needs_update ? "Update record" : "Updated")}
                                </Typography>
                                {tableData.needs_create || tableData.needs_update || tableData.needs_status ?
                                    <> {/* Create/ Update  */}
                                        <InputRow>
                                            <InputLabel key="L00">&nbsp;</InputLabel>
                                            <MuiText
                                                label="Raf Id"
                                                name="raf_id"
                                                disabled={true}
                                                defaultValue={formValue.raf_id}
                                                onChange={handleChange}
                                                key="raf_id"
                                            />
                                            <MuiText
                                                label="Referral Code"
                                                name="referral_code"
                                                disabled={true}
                                                defaultValue={formValue.referral_code}
                                                onChange={handleChange}
                                                key="referral_code"
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <FormControl
                                                key="C10"
                                                hidden={true}
                                            >
                                                <InputLabel
                                                    key="type-select-label"
                                                    style={{top: "-4px", backgroundColor:"white"}}
                                                >&nbsp;Type&nbsp;</InputLabel>
                                                <StyledSelect
                                                    labelId="type-select-label"
                                                    id="type-select"
                                                    name="a_type"
                                                    value={formValue.a_type || ''}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    disabled={tableData.needs_create}
                                                    key="type-select"
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    <MenuItem value="referrer">Referrer</MenuItem>
                                                    <MenuItem value="referred">Referred</MenuItem>
                                                </StyledSelect>
                                            </FormControl>

                                            <InputLabel key="L13"></InputLabel>
                                        </InputRow>
                                        <InputRow>
                                            <MuiText
                                                label="Account Number"
                                                name="account_number"
                                                required={true}
                                                defaultValue={formValue.account_number}
                                                onChange={handleChange}
                                                disabled={tableData.needs_status}
                                                onBlur={(e) => handleAccountLookup(e.target.value)} // or use onChange for immediate lookup
                                                key="account_number"
                                            />
                                            <MuiText
                                                key="account_status"
                                                label="Account Status"
                                                name="account_status"
                                                disabled={true}
                                                value={formValue.account_status || ''}
                                                onChange={handleChange}
                                            />
                                            <Typography component="div" style={{visibility: 'hidden', display: 'flex', alignItems: 'center'}}>
                                                <strong>Name</strong> - {formValue.first_name} {formValue.last_name}
                                            </Typography>
                                        </InputRow>
                                        <InputRow>
                                            <MuiText
                                                label="Account Start"
                                                name="account_start"
                                                disabled={true}
                                                //defaultValue={formValue.account_start}
                                                value={formValue.account_start || ''}
                                                onChange={handleChange}
                                                key="account_start"
                                            />
                                            <MuiText
                                                label="Amount"
                                                name="amount_paid"
                                                disabled={tableData.needs_status}
                                                type={"number"}
                                                defaultValue={formValue.amount_paid}
                                                onChange={handleChange}
                                                key="amount_paid"
                                            />
                                            <FormControl
                                                key="C40"
                                            >
                                                <InputLabel
                                                    key="status1-select-label"
                                                    style={{top: "-5px", backgroundColor:"white"}}
                                                >&nbsp;Status&nbsp;</InputLabel>
                                                <StyledSelectShort
                                                    labelId="status1-select-label"
                                                    id="type-select"
                                                    name="raf_status"
                                                    displayEmpty
                                                    defaultValue={formValue.raf_status || ''}
                                                    onChange={handleChange}
                                                    key="status1-select"
                                                >
                                                    <MenuItem value=""><em></em></MenuItem>
                                                    <MenuItem value="1">Pending</MenuItem>
                                                    <MenuItem value="2">Approved</MenuItem>
                                                    <MenuItem value="7">Declined</MenuItem>
                                                </StyledSelectShort>
                                            </FormControl>
                                            {/*<InputLabel key="L413"></InputLabel>*/}
                                        </InputRow>
                                        <InputRow>
                                            <FormControl
                                                key="C11"
                                                style={{width:"125%"}}
                                            >
                                                <MuiText
                                                    label="Account Notes"
                                                    name="raf_notes"
                                                    style={{width:"115%"}}
                                                    defaultValue={(formValue.raf_notes || '')}
                                                    onChange={handleChange}
                                                    key="raf_notes"
                                                />
                                            </FormControl>

                                            <InputLabel key="L513"></InputLabel>
                                        </InputRow>
                                        <br/>
                                        <InputRow>
                                            <MuiText
                                                label="Ref Account Number"
                                                name="raf_account_number"
                                                defaultValue={formValue.raf_account_number ? formValue.raf_account_number : (formValue.ref_account_number ? formValue.ref_account_number : '')}
                                                onChange={handleChange}
                                                disabled={tableData.needs_status}
                                                onBlur={(e) => handleAccountLookup(e.target.value, true)} // true indicates this is the ref account
                                                key="raf_account_number"
                                            />
                                            <MuiText
                                                label="Ref Account Status"
                                                name="raf_account_status"
                                                disabled={true}
                                                value={formValue.raf_account_status || (formValue.ref_account_status || '')}
                                                onChange={handleChange}
                                                key="raf_account_status"
                                            />
                                            <InputLabel key="L43"></InputLabel>
                                        </InputRow>
                                        <InputRow>
                                            <MuiText
                                                label="Ref Account Start"
                                                name="raf_account_start"
                                                disabled={true}
                                                value={formValue.raf_account_start || (formValue.ref_account_start || '')}
                                                onChange={handleChange}
                                                key="raf_account_start"
                                            />
                                            <MuiText
                                                label="Ref Amount"
                                                name="rc_amount_paid"
                                                type={"number"}
                                                disabled={tableData.needs_status}
                                                defaultValue={formValue.rc_amount_paid}
                                                onChange={handleChange}
                                                key="rc_amount_paid"
                                            />
                                            <FormControl
                                                key="C50"
                                            >
                                                <InputLabel
                                                    key="status2-select-label"
                                                    style={{top: "-5px", backgroundColor:"white"}}
                                                >&nbsp;Status&nbsp;</InputLabel>
                                                <StyledSelectShort
                                                    labelId="status2-select-label"
                                                    id="type-select"
                                                    name="rc_status"
                                                    displayEmpty
                                                    defaultValue={formValue.rc_status || ''}
                                                    onChange={handleChange}
                                                    key="status2-select"
                                                >
                                                    <MenuItem value=""><em></em></MenuItem>
                                                    <MenuItem value="1">Pending</MenuItem>
                                                    <MenuItem value="2">Approved</MenuItem>
                                                    <MenuItem value="7">Declined</MenuItem>
                                                </StyledSelectShort>
                                            </FormControl>
                                        </InputRow>
                                        <InputRow>
                                            <FormControl
                                                key="C11"
                                                style={{width:"125%"}}
                                            >
                                                <MuiText
                                                    label="Ref Account Notes"
                                                    name="rc_notes"
                                                    type={"text"}
                                                    style={{width: "115%"}}
                                                    defaultValue={formValue.rc_notes ?? ''}
                                                    onChange={handleChange}
                                                    key="rc_notes"
                                                />
                                            </FormControl>
                                            <InputLabel key="L53"></InputLabel>
                                        </InputRow>
                                        <br/>
                                        <InputRow>
                                            <ThemedButton
                                                onClick={handleCancel}
                                                outlined={'true'}
                                                key="b1"
                                            >
                                                Cancel
                                            </ThemedButton>
                                            <InputLabel key="L10"></InputLabel>
                                            <ThemedButton
                                                contained={'true'}
                                                onClick={handleSubmit}
                                                key="b2"
                                                disabled={createDisabled()}
                                            >
                                                {tableData.needs_create? "Create" : "Save"}
                                            </ThemedButton>
                                        </InputRow>
                                    </> : <>
                                        <InputRow>
                                            <MuiText
                                                label="Raf Id"
                                                name="raf_id"
                                                defaultValue={formValue.raf_id}
                                                onChange={handleChange}
                                                key="raf_id"
                                            />
                                            <MuiText
                                                label="Type"
                                                name="a_type"
                                                defaultValue={formValue.a_type}
                                                key="type"
                                            />

                                            <MuiText
                                                label="Referral Code"
                                                name="referral_code"
                                                defaultValue={formValue.referral_code}
                                                onChange={handleChange}
                                                key="referral_code"
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <MuiText
                                                label={"Account Number" + (formValue.a_type==='referer' ? " *" : "")}
                                                name="account_number"
                                                defaultValue={formValue.account_number}
                                                onChange={handleChange}
                                                key="account_number"
                                            />
                                            <MuiText
                                                label="Account Status"
                                                name="account_status"
                                                defaultValue={formValue.account_status}
                                                onChange={handleChange}
                                                key="account_status"
                                            />
                                            <MuiText
                                                label="Account Start"
                                                name="account_start"
                                                defaultValue={formValue.account_start}
                                                onChange={handleChange}
                                                key="account_start"
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <InputLabel key="L111">&nbsp;</InputLabel>
                                            <MuiText
                                                label="Amount Paid"
                                                name="amount_paid"
                                                defaultValue={formValue.amount_paid}
                                                onChange={handleChange}
                                                key="amount_paid"
                                            />
                                            <MuiText
                                                label="Created By"
                                                name="created_by_username"
                                                defaultValue={formValue.raf_created_by_username || ''}
                                                onChange={handleChange}
                                                key="created_by_username"
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <hr/>
                                            <hr/>
                                            <hr/>
                                        </InputRow>
                                        <InputRow>
                                            <MuiText
                                                label="Ref Raf Id"
                                                name="raf_record_id"
                                                defaultValue={formValue.raf_record_id}
                                                onChange={handleChange}
                                                key="bill_id"
                                            />
                                            <InputLabel key="L122">&nbsp;</InputLabel>
                                            <InputLabel key="L123">&nbsp;</InputLabel>
                                        </InputRow>
                                        <InputRow>
                                            <MuiText
                                                label={"Referrer Account Number"}
                                                name="ref_account_number"
                                                defaultValue={formValue.raf_account_number ?? (formValue.ref_account_number ?? '')}
                                                key="ref_account_number"
                                            />

                                            <MuiText
                                                label="Account Status"
                                                name="ref_account_status"
                                                type={"text"}
                                                defaultValue={formValue.raf_account_status ?? (formValue.ref_account_status ?? '')}
                                                onChange={handleChange}
                                                key="ref_account_status"
                                            />
                                            <MuiText
                                                label="Account Start"
                                                name="ref_account_start"
                                                defaultValue={formValue.raf_account_start ?? (formValue.ref_account_start ?? '')}
                                                onChange={handleChange}
                                                key="ref_account_start"
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <InputLabel key="L00">&nbsp;</InputLabel>
                                            <MuiText
                                                label="Ref Amount Paid"
                                                name="raf_amount_paid"
                                                defaultValue={formValue.rc_amount_paid}
                                                onChange={handleChange}
                                                key="amount_paid"
                                            />

                                            <MuiText
                                                label="Created By"
                                                name="created_by_username"
                                                defaultValue={formValue.rc_created_by_username || ''}
                                                onChange={handleChange}
                                                key="created_by_username"
                                            />
                                        </InputRow>
                                        * Customer that received the credit
                                        <br/>
                                        <ThemedButton
                                            onClick={handleCancel}
                                            outlined={'true'}
                                            key="b1"
                                        >
                                            Close
                                        </ThemedButton>
                                    </>
                                }
                        </div>
                    )}
                </form>
            </div>
        </>
        ,
        document.getElementById('portal')
    )
};
export default ReferralModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width:
            "55%",
        height:
            "96vh",
        overflowX:
            "hidden",
        top:
            '50%',
        left:
            '50%',
        transform:
            'translate(-50%, -50%)',
        backgroundColor:
            '#FFF',
        borderRadius:
            10,
        padding:
            '60px 8vw',
        zIndex:
            1000,
        [theme.breakpoints.down("lg")]
    :
        {
            width: "70%",
        }
    ,
        [theme.breakpoints.down("md")]
    :
        {
            width: "90%",
        }
    }
,
    forCheckButton: {
        display: 'flex',
            flexDirection
    :
        'row',
            justifyContent
    :
        'flex-start',
            alignItems
    :
        'center',
            backgroundColor
    :
        'none',
            padding
    :
        '0px 0px 20px 0px',
            cursor
    :
        'pointer',
            position
    :
        'absolute',
    }
,
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    }
}))