import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import {default as OneRowReferralSelector, handleUnselect} from "../../components/OneRowReferralSelector";
import {Menu, MenuItem, Table} from "@mui/material";
import {EditOutlined} from "@mui/icons-material";
import moment from "moment";
import ReferralModal from "../../components/modals/ReferralModal";
import Button from "react-bootstrap/Button";
import {CSVLink} from "react-csv";
const openInit = {
    createReferral: false,
    editReferral: false,
    editType: 'update',
    deactivate: false,
    userPortal: false
}

export const Referrals =  (props) => {
    const [allItems, setAllItems] =  useState([]);
    const [referralsData, setReferralsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [anchorAction, setAnchorAction] = useState(null);
    const [referralData, setReferralData] = useState([]);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [shownStatuses, setShownStatuses] = useState([null,1,2,3,4,5,6,7,8]);
    let filteredItems = referralsData.filter(
        item => (shownStatuses.includes(item.raf_status) || shownStatuses.includes(item.rc_status)),
    );
    const disableDownload = false;
    const [isOpen, setIsOpen] = useState(openInit);
    const handleClose = async (newRecord, doUpdate = false) => {

        setIsOpen(openInit);

        //console.log('handleClose',referralData);
        handleUnselect(referralData,referralData);
        if (doUpdate) {
            //console.log('newRecord', newRecord);
            newRecord.a_id = referralData.a_id;
            setReferralsData(prevData => prevData.map(row =>
                row.a_id === newRecord.a_id ? { ...row, ...newRecord } : row
            ));
        }

    };
    const setFilterStatuses =  (id) => {
        console.log(id.target.value);
        switch (id.target.value) {
            case 'new' :
                setShownStatuses([null]);
                break;
            case 'pending' :
                setShownStatuses([1]);
                break;
            case 'approved' :
                setShownStatuses([2]);
                break;
            case 'rejected' :
                setShownStatuses([7]);
                break;
            case 'applied' :
                setShownStatuses([3]);
                break;
            case 'paid' :
                setShownStatuses([4,5]);
                break;
            case 'all':
            default:
                setShownStatuses([null,1,2,3,4,5,6,7]);
                break;
        }
        console.log(shownStatuses);
    }
    const handleActionClose = () => {
        setAnchorAction(null);
    };
    const handleExpandReferral = (row,tableData) => {
        const data = row;
        //console.log('handleExpandReferral:row',row);
        //console.log('handleExpandReferral:tdl',tableData.length);
        //console.log('handleExpandReferral:td',tableData);

            if (data.needs_create) {
                //console.log('handle create');
                handleActionClose();
                setReferralData(data);
                setIsOpen({ ...openInit, editReferral: true, editType: 'create' })
            } else if (data.needs_update) {
                //console.log('handle edit');
                handleActionClose();
                setReferralData(data);
                setIsOpen({ ...openInit, editReferral: true, editType: 'update' })
            } else {
                //console.log('already updated');
                handleActionClose();
                setReferralData(data);
                setIsOpen({ ...openInit, editReferral: true, editType: 'updated' })
            }

    }

    const ExpandedComponent = ({ data }) =>
        <Table striped bordered hover responsive="sm">
            <tr>
                <td><strong>#</strong></td>
                <td><strong>Type</strong></td>
                <td><strong>Account</strong></td>
                <td><strong>Account Status</strong></td>
                <td><strong>Start Date</strong></td>
                <td><strong>Amount</strong></td>
                <td><strong>Referral Status</strong></td>
                <td><strong>Enrollment Date</strong></td>
                <td><strong>Approval</strong></td>
                <td><strong>Last Updated</strong></td>
            </tr>
            <tr>
                <td>{data.a_id}</td>
                <td>Enrolling Account</td>
                <td>{data.raf_account_number}<br/>{data.raf_account_id ? `(${data.raf_account_id})` : ''}</td>
                <td>{data.raf_account_status}</td>
                <td>{data.raf_account_start ? moment(new Date(data.raf_account_start)).format("YYYY-MM-DD") : ''}</td>
                <td>{!data.raf_amount_paid ? '' : data.raf_amount_paid}</td>
                <td>{data.raf_internal_status_name}</td>
                <td>{data.raf_enrolled_date ? moment(new Date(data.raf_enrolled_date)).format("YYYY-MM-DD") : ''}</td>
                <td>{data.raf_approval_date ? moment(new Date(data.raf_approval_date)).format("YYYY-MM-DD") : ''}</td>
                <td>{data.raf_updated_date ? moment(new Date(data.raf_updated_date)).format("YYYY-MM-DD") : ''}</td>
            </tr>
            <tr>
                <td><strong>Valid</strong></td>
                <td><strong></strong></td>
                <td><strong></strong></td>
                <td><strong></strong></td>
            </tr>
            <tr>
                <td>{data.a_type === 'invalid' ? 'N' : 'Y'}</td>
                <td>Referrer Account</td>
                <td>
                    {data.raf_ref_account_number}<br/>{data.raf_ref_account_id ? `(${data.raf_ref_account_id})` : ''}
                </td>
                <td>{data.raf_ref_account_status}</td>
                <td>{data.raf_ref_account_start ? moment(new Date(data.raf_ref_account_start)).format("YYYY-MM-DD") : ''}</td>
                <td>{!data.rc_amount_paid ? '' : data.rc_amount_paid}</td>
                <td>{data.rc_internal_status_name}</td>
                <td>{ ''}</td>
                <td>{data.rc_approval_date ? moment(new Date(data.rc_approval_date)).format("YYYY-MM-DD") : ''}</td>
                <td>{data.rc_updated_date ? moment(new Date(data.rc_updated_date)).format("YYYY-MM-DD") : ''}</td>
            </tr>

            <tr>
                <td><strong>Referral Code</strong></td>
                <td><strong></strong></td>
                <td><strong></strong></td>
                <td><strong></strong></td>
                <td><strong></strong></td>
            </tr>
            <tr>
                <td>{data.raf_referral_code}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </Table>
    ;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText:
            'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50, 100, 250, 500, 1000, 2000
    ];
    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: '12px',
                paddingLeft:'0 0px',
                paddingRight:'0 0px',
                fontWeight: 'bold',
                /*paddingLeft: '0 8px',*/
                justifyContent: 'center',
                /*backgroundColor: '#FFA500'*/
            },
        },
    }
    const conditionalRowStyles = [

        {
            when: row => row.needs_update,
            style: {
                backgroundColor: 'lightblue',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.needs_create,
            style: {
                backgroundColor: 'lightyellow',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.a_type === 'invalid',
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.raf_account_id  === row.raf_raf_account_id,
            style: {
                backgroundColor: 'orange',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }

        // You can also pass a callback to style for additional customization
        // {
        //     when: row => row.calories < 400,
        //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        //},
    ];
    const handleSelectType = (event) => {
        //console.log(event.target);
        //console.log(event.target.selectedOptions[0].value);
        const raf_id = event.target.getAttribute('raf_id')
        //console.log(event.target.getAttribute('raf_id'));
        if (event.target.selectedOptions[0].value === '1') {
            //console.log('in')
            document.getElementById('refacc_' + raf_id).value = document.getElementById('acc_' + raf_id).value;
            document.getElementById('acc_' + raf_id).value = null;
        } else {
            //console.log('not');
            //console.log(event.target.selectedOptions[0].value);
        }
    }
    const onFilter = (e) => {
        setFilterText(e.target.value);
        //console.log(e);
        e.target.focus();
    };
    const now = new Date();
    const fileDate= now.getFullYear() + '_' + now.getMonth() + '_' +now.getDate();
    const exportHeaders = [
        { label: 'NewAccount', key: 'raf_account_number' },
        { label: 'CodeUsed', key: 'raf_referral_code' },
        { label: 'NewAccountAmount', key: 'raf_amount_paid' },
        { label: 'NewAccountStart', key: 'raf_account_start' },
        { label: 'NewAccountStatus', key: 'raf_account_status' },
        { label: 'OldAccount', key: 'raf_ref_account_number' },
        { label: 'OldAccountStart', key: 'raf_ref_account_start' },
        { label: 'OldAccountStatus', key: 'raf_ref_account_status' },
        { label: 'OldAccountAmount', key: 'raf_rc_amount_paid' },
        { label: 'ReferralStatus', key: 'raf_internal_status_name' },
        { label: 'ValidRecord', key: 'raf_is_valid' },
    ];
    useEffect(() => {
        const init = async () => {
            await fetchReferrals(1);
            await setColumns([
                {
                    name: '#',
                    selector: row => [3,4,7].includes(row.raf_status) ? '' : <>{row.a_id}<OneRowReferralSelector row={row} tableData={tableData} setTableData={setTableData} handler={handleExpandReferral}/></>,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                },
                /*{
                    name: '#',
                    selector: row => row.a_id,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "1.5%",
                },*/
                {
                    name: 'Valid',
                    selector: row => row.a_type === 'invalid' ? 'N' : 'Y',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2.5%",
                },
                {
                    name: 'Enroll Date',
                    selector: row => row.raf_enrolled_date ? moment(new Date(row.raf_enrolled_date)).format("YYYY-MM-DD") : (row.raf_account_start ? moment(new Date(row.raf_account_start)).format("YYYY-MM-DD") : ''),
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'ReferralCode',
                    selector: row => row.raf_referral_code,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Account',
                    selector: row => row.raf_account_number,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                /*{
                    name: 'Start',
                    selector: row => row.raf_account_start ? moment(new Date(row.raf_account_start)).format("YYYY-MM-DD"): '',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },*/
                {
                    name: 'Status',
                    selector: row => row.raf_account_status,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'Amount',
                    selector: row => row.raf_amount_paid,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3.5%",
                },

                {
                    name: 'L.Updated',
                    selector: row => row.raf_updated_date ? moment(new Date(row.raf_updated_date)).format("YYYY-MM-DD") :  '',
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'RafStatus',
                    selector: row => row.raf_internal_status_name  ? row.raf_internal_status_name : 'new',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'RefAccount',
                    selector: row => row.rc_raf_account_number ? row.rc_raf_account_number : (row.raf_ref_account_number ? row.raf_ref_account_number : null),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Status',
                    selector: row => row.rc_raf_account_status  ? row.rc_raf_account_status : (row.raf_ref_account_status  ? row.raf_ref_account_status : null),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                /*{
                    name: 'Start',
                    selector: row => row.rc_raf_account_start ? moment(new Date(row.rc_raf_account_start)).format("YYYY-MM-DD") : (row.raf_ref_account_start ? moment(new Date(row.raf_ref_account_start)).format("YYYY-MM-DD") : null),
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },*/
                {
                    name: 'Amount',
                    selector: row => row.rc_amount_paid,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3.5%",
                },
                /*{
                    name: 'E.Date',
                    selector: row => row.rc_enrolled_date ? moment(new Date(row.rc_enrolled_date)).format("YYYY-MM-DD") : (row.rc_approval_date ? moment(new Date(row.rc_approval_date)).format("YYYY-MM-DD") : ''),
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },*/
                {
                    name: 'L.Updated',
                    selector: row => row.rc_updated_date ? moment(new Date(row.rc_updated_date)).format("YYYY-MM-DD") :  '',
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Status',
                    selector: row => row.rc_internal_status_name  ? row.rc_internal_status_name : '',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'RafId',
                    selector: row => row.raf_id,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2.5%",
                },
                {
                    name: 'User',
                    selector: row => row.raf_created_by_username,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2.5%",
                },
                {
                    name: 'Action',
                    selector: row => row.a_type==='invalid' ? '' :(row.needs_create ? 'create' : (row.needs_update ? 'update' : '')),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                }

            ]);
        }
        init().then(
            () => {
                //console.log('loaded');
            }
        );
    }, [tableData, totalRows]);
    const fetchReferral = async raf_id => {
        setLoading(true);
        let isOk = true;
        const response = await axios.get(`/internal/referral/${raf_id}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        ).catch((e)=>{
            //console.log(e);
            isOk = false;
        });
        if (isOk) {
            //console.log(response.data.data);
            //console.log(response.data.total);
            console.log(response.data.data);
            //await setReferralsData(response.data.data);
            //setTotalRows(response.data.total);
            setLoading(false);
        } else {

        }
    };
    const fetchReferrals = async page => {
        setLoading(true);
        let isOk = true;
        const response = await axios.get(`/internal/referrals?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        ).catch((e)=>{
            //console.log(e);
            isOk = false;
        });
        if (isOk) {
            //console.log(response.data.data);
            //console.log(response.data.total);
            await setTotalRows(response.data.total);
            await setReferralsData(response.data.data);

            setLoading(false);
        } else {

        }
    };
    const getAllItems = async (e) => {
        //console.log(e);
        setLoading(true);
        const response = await axios.get(`/internal/referrals?page=1&per_page=${totalRows}`,
            {headers: {'x-api-key': process.env.REACT_APP_API_KEY}}
        );
        setLoading(false);
        let rafItems= [];
        await response.data.data.map((item) => {
            //console.log(item.raf_status_name?.length);
            if (item.raf_status_name?.length === undefined) {
                item.raf_status_name = 'new';
            }
            if (item.a_type === 'invalid') {
                item.raf_is_valid = false;
            } else {
                item.raf_is_valid = true;
            }
            rafItems.push(item);
        });
        //console.log(rafItems);
        await setAllItems(rafItems);

    }
    const actionsMemo = React.useMemo(() =>
            <>
                {! disableDownload && allItems.length <= 0 ?<Button onClick={getAllItems}>Prepare Download  ({totalRows})</Button>: undefined}
                {! disableDownload && allItems.length > 0 ?
                    <CSVLink
                        data={allItems}
                        headers={exportHeaders}
                        filename={`gc_raf_${fileDate}.csv`}
                        className="btn btn-primary"
                    >
                        Export Referral Data
                    </CSVLink>
                    : undefined}
            </>,
        [allItems, totalRows]
    );

    return (
        <>
            {
                (loading || ! referralsData || referralsData.length <=0 || ! totalRows || totalRows <=0) ? (
                    <>
                        loading
                    </>
                ):(
                    <>
                        <div id="portal"></div>
                        {isOpen.editReferral && (
                            <ReferralModal open={isOpen.editReferral}
                                           onClose={handleClose}
                                           tableData={referralData}
                                           isOpen={isOpen}

                            />
                        )}
                        Filter: <Button onClick={setFilterStatuses} value={"all"}>All</Button>
                        <Button onClick={setFilterStatuses} value={"new"}>New</Button>
                        <Button onClick={setFilterStatuses} value={"pending"}>Pending</Button>
                        <Button onClick={setFilterStatuses} value={"approved"}>Approved</Button>
                        <Button onClick={setFilterStatuses} value={"rejected"}>Rejected</Button>
                        <Button onClick={setFilterStatuses} value={"applied"}>Applied</Button>
                        <Button onClick={setFilterStatuses} value={"paid"}>Paid</Button>
                        <>
                            <DataTable
                                title="Referrals"
                                columns={columns}
                                customStyles={tableCustomStyles}
                                conditionalRowStyles={conditionalRowStyles}
                                data={filteredItems}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                                fixedHeader
                                fixedHeaderScrollHeight="1000px"
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                paginationPerPage={perPage}
                                paginationDefaultPage={page}
                                progressPending={loading}
                                striped
                                responsive
                                dense
                                subHeader
                                autoResetSortBy={false}
                                actions={actionsMemo}
                            >
                            </DataTable>
                        </>
                    </>
                )
            }
        </>
    );
}